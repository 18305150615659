@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-Regular.woff2') format('woff2'),
        url('./Roobert-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-Heavy.woff2') format('woff2'),
        url('./Roobert-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-BoldItalic.woff2') format('woff2'),
        url('./Roobert-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-Light.woff2') format('woff2'),
        url('./Roobert-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-SemiBold.woff2') format('woff2'),
        url('./Roobert-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-Bold.woff2') format('woff2'),
        url('./Roobert-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-HeavyItalic.woff2') format('woff2'),
        url('./Roobert-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-Medium.woff2') format('woff2'),
        url('./Roobert-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-RegularItalic.woff2') format('woff2'),
        url('./Roobert-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-LightItalic.woff2') format('woff2'),
        url('./Roobert-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roobert';
    src: url('./Roobert-SemiBoldItalic.woff2') format('woff2'),
        url('./Roobert-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}