body {
  margin: 0;
  font-family: "Roobert";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root, main, .App {
	height: 100%
}

/* Disable chrome autofill input colour */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	50% { opacity: 0; }
	100% { opacity: 1; }
}